export default [{
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'BarChartIcon',
    action: 'manage'
  },
  {
    title: 'Customer',
    route: 'customers-list',
    icon: 'UserIcon',
    action: 'read'
  },
  {
    title: 'Treatment',
    route: 'treatments-list',
    icon: 'FileTextIcon',
    action: 'read'
  },
  {
    title: 'Employee',
    route: 'employees-list',
    icon: 'SmileIcon',
    action: 'manage'
  },
]